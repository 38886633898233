import React, { useEffect, useState } from 'react';
import ItemCard from '../components/ItemCard';
import { Badge, Button, useDisclosure, useToast } from '@chakra-ui/react';
import MenuCard from '../components/MenuCard';
import CategoryCard from '../components/CategoryCard';
import ItemModal from '../components/Modals/ItemModal';
import MenuModal from '../components/Modals/MenuModal';
import AddonModal from '../components/Modals/AddonModal';

import CategoryModal from '../components/Modals/CategoryModal';
import {
    deleteMenu,
    getAddons,
    getCategories,
    getItems,
    getMenus,
    getSubAddons,
    toggleEnableDisable,
} from '../services/apiService';
import Loading from '../components/Loading';
import { useSearchParams } from 'react-router-dom';
import SubAddonCard from '../components/SubAddonCard';
import SubAddonModal from '../components/Modals/SubAddonModal';
import { createPortal } from 'react-dom';

const MenuEdit = () => {
    const [menuList, setMenuList] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState({});
    const [selectedCategory, setSelectedCategory] = useState({});
    const [selectedItem, setSelectedItem] = useState({});
    const [selectedSubAddon, setSelectedSubAddon] = useState({});
    const [selectedAddon, setSelectedAddon] = useState({});
    const [categoriesList, setCategoriesList] = useState([]);
    const [itemsList, setItemsList] = useState([]);
    const [subAddonList, setSubAddonList] = useState([]);
    const [addonList, setAddonList] = useState([]);
    const [loading, setLoading] = useState({
        isMenuLoading: false,
        isCategoryLoading: false,
        isItemsLoading: false,
        isAddonLoading: false,
        isSubAddonLoading: false,
        deleteLoading: false,
    });

    const [selectedID, setSelectedID] = useState({
        menuID: null,
        categoryID: null,
        itemID: null,
        subAddonsID: null,
        addonsID: null,
    });
    const {
        isOpen: isMenuOpen,
        onOpen: menuOpen,
        onClose: menuClose,
    } = useDisclosure();

    const {
        isOpen: isCategoryOpen,
        onOpen: categoryOpen,
        onClose: categoryClose,
    } = useDisclosure();
    const {
        isOpen: isItemOpen,
        onOpen: itemOpen,
        onClose: itemClose,
    } = useDisclosure();
    const {
        isOpen: isSubAddonOpen,
        onOpen: subAddonOpen,
        onClose: subAddonClose,
    } = useDisclosure();
    const {
        isOpen: isAddonOpen,
        onOpen: addonOpen,
        onClose: addonClose,
    } = useDisclosure();
    const toast = useToast();
    const [searchParams] = useSearchParams();
    let menuID = searchParams.get('mid');
    let categoryID = searchParams.get('cid');
    let itemID = searchParams.get('iid');

    useEffect(() => {
        setSelectedID({
            menuID: +menuID,
            categoryID: +categoryID,
            itemID: +itemID,
        });
    }, [menuID, categoryID, itemID]);
    useEffect(() => {
        fetchMenus();
    }, []);
    useEffect(() => {
        if (selectedID && selectedID.menuID) {
            fetchCategories(selectedID?.menuID);
        }
    }, [selectedID?.menuID]);
    useEffect(() => {
        if (selectedID && selectedID.categoryID) {
            fetchItems(selectedID.categoryID);
        }
    }, [selectedID.categoryID]);
    useEffect(() => {
        if (selectedID && selectedID.itemID) {
            fetchSubAddons(selectedID.itemID);
        }
    }, [selectedID.itemID]);
    useEffect(() => {
        if (selectedID && selectedID.subAddonsID && selectedID.itemID) {
            fetchAddons(selectedID.itemID, selectedID.subAddonsID);
        }
    }, [selectedID.subAddonsID]);

    const fetchMenus = async () => {
        setLoading({ ...loading, isMenuLoading: true });
        try {
            const response = await getMenus();
            if (response.status === 200) {
                let menus = response.data?.data?.menus || [];
                if (menus.length) {
                    if (selectedID.menuID) {
                        const lastSelectedMenu = menus.find(
                            (menu) => menu.id === selectedID.menuID
                        );
                        if (lastSelectedMenu) {
                            setSelectedMenu(lastSelectedMenu);
                            setSelectedID({
                                ...selectedID,
                                menuID: lastSelectedMenu.id,
                            });
                        } else {
                            setSelectedMenu(menus[0]);
                            setSelectedID({
                                ...selectedID,
                                menuID: menus[0].id,
                            });
                        }
                    } else {
                        setSelectedMenu(menus[0]);
                        setSelectedID({ ...selectedID, menuID: menus[0].id });
                    }
                }
                setMenuList(menus);
            }
        } catch (error) {
        } finally {
            setLoading({ ...loading, isMenuLoading: false });
        }
    };
    const fetchCategories = async (id) => {
        setLoading({ ...loading, isCategoryLoading: true });
        setCategoriesList([])
        try {
            const response = await getCategories(id);
            if (response.status === 200) {
                const categories = response.data?.data?.categories || [];
                // if (categories.length) {
                // setSelectedCategory(categories[0]);
                // }
                setCategoriesList(categories);
            }
        } catch (error) {
        } finally {
            setLoading({ ...loading, isCategoryLoading: false });
        }
    };
    const fetchItems = async (id) => {
        setLoading({ ...loading, isItemsLoading: true });
        try {
            const response = await getItems(id);
            if (response.status === 200) {
                const items = response.data?.data?.items || [];
                setItemsList(items);
            }
        } catch (error) {
        } finally {
            setLoading({ ...loading, isItemsLoading: false });
        }
    };
    const fetchSubAddons = async (id) => {
        setLoading({ ...loading, isSubAddonLoading: true });
        setSubAddonList([])
        try {
            const response = await getSubAddons(id);
            if (response.status === 200) {
                const subaddons = response.data?.data?.subaddons || [];
                setSubAddonList(subaddons);
            }
        } catch (error) {
        } finally {
            setLoading({ ...loading, isSubAddonLoading: false });
        }
    };
    const fetchAddons = async (itemID, id) => {
        setLoading({ ...loading, isAddonLoading: true });
        setAddonList([])
        try {
            const response = await getAddons(itemID, id);
            if (response.status === 200) {
                const addons = response.data?.data?.addons || [];
                setAddonList(addons);
            }
        } catch (error) {
        } finally {
            setLoading({ ...loading, isAddonLoading: false });
        }
    };

    const deleteClickHandler = async (type, id, cb) => {
        try {
            setLoading({ ...loading, deleteLoading: true });

            const response = await deleteMenu(type, id);
            if (response.status === 201 || response.status === 200) {
                fetchMenus();
                cb();
            }
        } catch (error) {
        } finally {
            setLoading({ ...loading, deleteLoading: false });
        }
    };
    const enableToggleHandler = async (type, id, cb) => {
        try {
            const response = await toggleEnableDisable(type, id);
            if (response.status === 200) {
                const msg =
                    response.data.data ||
                    `${type} has been updated successfully`;
                toast({
                    title: msg,
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                });
                cb();
            }
        } catch (error) {
            toast({
                title: 'Something went wrong!',
                description: 'Please try again after sometime.',
                status: 'error',
                duration: 2000,
                isClosable: true,
            });
        }
    };
    const handleEditClickHandler = (
        type,
        item,
        isEdit = true,
        openModal,
        openModalCallback,
        selectedStoreCallback
    ) => {
        if (isEdit) {
            if(type==="menuID"){
                setSelectedID({ ...selectedID, categoryID: null, itemID: null, subAddonsID: null, addonsID: null, [type]: item.id });
            } else if (type==="categoryID") {
                setSelectedID({ ...selectedID, itemID: null, subAddonsID: null, addonsID: null, [type]: item.id });
            } else if (type==="itemID") {
                setSelectedID({ ...selectedID, subAddonsID: null, addonsID: null, [type]: item.id });
            } else if(type==="subAddonsID") {
                setSelectedID({ ...selectedID, addonsID: null, [type]: item.id });
            }else{
                setSelectedID({ ...selectedID, [type]: item.id });
            }
            selectedStoreCallback(item);
        } else {
            selectedStoreCallback({});
        }
        if (openModal) {
            openModalCallback();
        }
    };
    return (
        <>
            <div className='editmenu-container'>
                {loading.deleteLoading && createPortal(
                    <div className='global-loader'>
                        <Loading isLogo />
                    </div>, document.body
                )}
                <div className='add-menu-options'>
                    <div className='menus-wrapper'>
                        <div className='header-items'>
                            <Badge colorScheme='blue'>Menus</Badge>
                            <Button
                                colorScheme='white'
                                marginTop={'0.5rem'}
                                fontSize={'13px'}
                                variant='outline'
                                onClick={() =>
                                    handleEditClickHandler(
                                        'menuID',
                                        {},
                                        false,
                                        true,
                                        menuOpen,
                                        setSelectedMenu
                                    )
                                }
                            >
                                Add Menu
                            </Button>
                        </div>
                        {menuList.map((menu) => (
                            <MenuCard
                                key={menu.id}
                                item={menu}
                                onOpen={menuOpen}
                                selectedMenu={selectedMenu}
                                selected={selectedID.menuID === menu.id}
                                deleteClickHandler={deleteClickHandler}
                                enableToggleHandler={enableToggleHandler}
                                fetchMenus={fetchMenus}
                                cardClick={() =>
                                    handleEditClickHandler(
                                        'menuID',
                                        menu,
                                        true,
                                        false,
                                        menuOpen,
                                        setSelectedMenu
                                    )
                                }
                                editClick={() =>
                                    handleEditClickHandler(
                                        'menuID',
                                        menu,
                                        true,
                                        true,
                                        menuOpen,
                                        setSelectedMenu
                                    )
                                }
                            />
                        ))}
                    </div>
                    <div className='categories-wrapper'>
                        <div className='header-items'>
                            <Badge colorScheme='blue'>Categories</Badge>
                            <Button
                                colorScheme='white'
                                marginTop={'0.5rem'}
                                fontSize={'13px'}
                                variant='outline'
                                onClick={() =>
                                    handleEditClickHandler(
                                        'categoryID',
                                        {},
                                        false,
                                        true,
                                        categoryOpen,
                                        setSelectedCategory
                                    )
                                }
                            >
                                Add Category
                            </Button>
                        </div>
                        {loading.isCategoryLoading ? (
                            <Loading />
                        ) : categoriesList.length === 0 ? (
                            <Loading title={'No category found!'} />
                        ) : (
                            categoriesList.map((category) => (
                                <CategoryCard
                                    key={category.id}
                                    item={category}
                                    onOpen={categoryOpen}
                                    selectedCategory={selectedCategory}
                                    selectedID={selectedID}
                                    selected={
                                        selectedID.categoryID === category.id
                                    }
                                    deleteClickHandler={deleteClickHandler}
                                    enableToggleHandler={enableToggleHandler}
                                    fetchCategories={fetchCategories}
                                    cardClick={() =>
                                        handleEditClickHandler(
                                            'categoryID',
                                            category,
                                            true,
                                            false,
                                            categoryOpen,
                                            setSelectedCategory
                                        )
                                    }
                                    editClick={() =>
                                        handleEditClickHandler(
                                            'categoryID',
                                            category,
                                            true,
                                            true,
                                            categoryOpen,
                                            setSelectedCategory
                                        )
                                    }
                                />
                            ))
                        )}
                    </div>
                </div>
                <div className='menu-item-view'>
                    {selectedID.categoryID ? (
                        <React.Fragment>
                        <div className='header-items'>
                            <Badge colorScheme='blue'>Items list</Badge>
                            <Button
                                colorScheme='white'
                                marginTop={'0.5rem'}
                                fontSize={'13px'}
                                variant='outline'
                                onClick={() =>
                                    handleEditClickHandler(
                                        'itemID',
                                        {},
                                        false,
                                        true,
                                        itemOpen,
                                        setSelectedItem
                                    )
                                }
                            >
                                Add Item
                            </Button>
                        </div>
                    {loading.isItemsLoading ? (
                        <Loading />
                    ) : selectedID.categoryID && itemsList.length === 0 ? (
                        <Loading title={'No Items found!'} />
                    ) : (
                        itemsList.map((item) => (
                            <ItemCard
                                item={item}
                                key={item.id}
                                image={item.image}
                                price={item.price}
                                onOpen={itemOpen}
                                selected={selectedID.itemID === item.id}
                                deleteClickHandler={deleteClickHandler}
                                enableToggleHandler={enableToggleHandler}
                                fetchItems={fetchItems}
                                selectedID={selectedID}
                                cardClick={() =>
                                    handleEditClickHandler(
                                        'itemID',
                                        item,
                                        true,
                                        false,
                                        itemOpen,
                                        setSelectedItem
                                    )
                                }
                                editClick={() =>
                                    handleEditClickHandler(
                                        'itemID',
                                        item,
                                        true,
                                        true,
                                        itemOpen,
                                        setSelectedItem
                                    )
                                }
                            />
                        ))
                    )}
                        </React.Fragment>
                    ) : null}
                </div>
                <div className='addons-view'>
                    {selectedID.itemID ? (
                        <React.Fragment>
                            <div className='header-items'>
                                <Badge colorScheme='blue'>Customization</Badge>
                                <Button
                                    colorScheme='white'
                                    marginTop={'0.5rem'}
                                    fontSize={'13px'}
                                    variant='outline'
                                    onClick={() =>
                                        handleEditClickHandler(
                                            'subAddonsID',
                                            {},
                                            false,
                                            true,
                                            subAddonOpen,
                                            setSelectedSubAddon
                                        )
                                    }
                                >
                                    Add Customization
                                </Button>
                            </div>
                            {loading.isSubAddonLoading ? (
                                <Loading />
                            ) : selectedID.itemID && subAddonList.length === 0 ? (
                                <Loading title={'No Customization found!'} />
                            ) : (
                                subAddonList.map((item) => (
                                    <SubAddonCard
                                        key={item.id}
                                        selected={selectedID.subAddonsID === item.id}
                                        cardClick={() =>
                                            handleEditClickHandler(
                                                'subAddonsID',
                                                item,
                                                true,
                                                false,
                                                subAddonOpen,
                                                setSelectedSubAddon
                                            )
                                        }
                                        editClick={() =>
                                            handleEditClickHandler(
                                                'subAddonsID',
                                                item,
                                                true,
                                                true,
                                                subAddonOpen,
                                                setSelectedSubAddon
                                            )
                                        }
                                        item={item}
                                        addonList={addonList}
                                        isAddonLoading={loading.isAddonLoading}
                                        deleteClickHandler={deleteClickHandler}
                                        enableToggleHandler={enableToggleHandler}
                                        fetchSubAddons={fetchSubAddons}
                                        fetchAddons={fetchAddons}
                                        selectedID={selectedID}
                                        handleEditClickHandler={handleEditClickHandler}
                                        setSelectedAddon={setSelectedAddon}
                                        addonOpen={addonOpen}
                                    />
                                ))
                            )}
                        </React.Fragment>
                    ) : null}
                </div>
            </div>

            {isMenuOpen && (
                <MenuModal
                    isOpen={isMenuOpen}
                    onOpen={menuOpen}
                    onClose={menuClose}
                    selectedMenu={selectedMenu}
                    fetchMenus={fetchMenus}
                />
            )}
            {isCategoryOpen && (
                <CategoryModal
                    isOpen={isCategoryOpen}
                    onOpen={categoryOpen}
                    onClose={categoryClose}
                    selectedCategory={selectedCategory}
                    selectedID={selectedID}
                    fetchCategories={fetchCategories}
                />
            )}
            {isItemOpen && (
                <ItemModal
                    isOpen={isItemOpen}
                    onOpen={itemOpen}
                    onClose={itemClose}
                    selectedItem={selectedItem}
                    selectedID={selectedID}
                    fetchItems={fetchItems}
                />
            )}
            {isSubAddonOpen && (
                <SubAddonModal
                    isOpen={isSubAddonOpen}
                    onOpen={subAddonOpen}
                    onClose={subAddonClose}
                    selectedSubAddon={selectedSubAddon}
                    selectedID={selectedID}
                    fetchSubAddons={fetchSubAddons}
                />
            )}
            {isAddonOpen && (
                <AddonModal
                    isOpen={isAddonOpen}
                    onOpen={addonOpen}
                    onClose={addonClose}
                    selectedAddon={selectedAddon}
                    selectedID={selectedID}
                    fetchAddons={fetchAddons}
                />
            )}
        </>
    );
};

export default MenuEdit;
